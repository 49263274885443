<template>
  <div class="rankingList">
    <div class="aboutTop">
      <Breadcrumb separator=">" class="bread">
        <BreadcrumbItem to="/" class="textColorO">首页</BreadcrumbItem>
        <BreadcrumbItem class="textColor">排行榜</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <div class="rankingListTop">
      <div class="rankingListTopB">
        <div class="click_left" @click="click_right">
          <i class="iconfont iconarrow-left"></i>
        </div>
        <div class="rankingListTopBM">
          <div
            class="rankingListTopBox"
            :style="`transform: translateX(-${100 * clickCount}px);`"
          >
            <div
              v-for="(item, index) in listData"
              :key="index"
              @click="listCLick(item.categoryId)"
            >
              <span>{{ item.categoryName }}</span>
              <img
                v-if="rankOne == item.categoryId"
                src="@/assets/images/rankOne.png"
                alt=""
                class="randOne"
              />
            </div>
          </div>
        </div>

        <div class="click_right" @click="click_left">
          <i class="iconfont iconarrow-right"></i>
        </div>
      </div>
    </div>
    <div class="rankingListMain">
      <div class="rankingListMainBox">
        <div class="rankingListMainBoxTop">
          <div
            class="rankingListMainBoxTopBox"
            v-for="(item, index) in goodList"
            :key="index"
            @click="goodsDetail(item)"
          >
            <img
              :src="require('@/assets/images/rank' + index + '.png')"
              alt=""
              class="randListImg"
            />
            <div class="divOne">{{ index + 1 }}</div>
            <div
              class="divTwo"
              :style="
                index + 1 == 1
                  ? 'color: #E64747;'
                  : index + 1 == 2
                  ? 'color: #F8871D;'
                  : index + 1 == 3
                  ? 'color: #FBD572;'
                  : ''
              "
            >
              热卖指数{{ item.ind }}
            </div>
            <div class="divThree">
              <img :src="item.goodsPic" alt="" class="divThreeImg" />
              <div>
                <p>{{ item.goodsName }}</p>
                <p></p>
                <p>
                  <span>￥{{ item.goodsDiscountPrice }}</span
                  ><span>￥{{ item.goodsCommodityPrice }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="rankingListMainBoxBody">
          <div
            class="divFive"
            v-for="(item, index) in listOne"
            :key="index"
            @click="goodsDetail(item)"
          >
            <!-- <img src="@/assets/images/rankFive.png" alt="" class="divFiveImg"> -->
            <!-- <div class="divfix">{{index+4}}</div> -->
            <div class="divBox">
              <img :src="item.goodsPic" alt="" />
            </div>
            <p class="hotspotBoxSmp">{{ item.goodsName }}</p>
            <div class="hotspotBoxPri">
              <div>
                <span>¥{{ item.goodsDiscountPrice }}</span
                ><span>￥{{ item.goodsCommodityPrice }}</span>
              </div>
              <div></div>
            </div>
          </div>
        </div>
        <Row class="mb20">
          <Page
            class="mt20 mr20 fr"
            size="small"
            show-elevator
            show-sizer
            show-total
            :total="total"
            :page-size="searchForm.pageSize"
            :current.sync="searchForm.page"
            @on-change="search"
            @on-page-size-change="
              (pageSize) => {
                (searchForm.pageSize = pageSize), search();
              }
            "
          />
        </Row>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/util/api";
export default {
  name: "rankingList",
  components: {},
  data() {
    return {
      clickCount: 0,
      total: 0,
      searchForm: {
        page: 1,
        pageSize: 20,
        search: {},
      },
      list: [
        { name: "墓地用品" },
        { name: "骨灰盒" },
        { name: "寿衣" },
        { name: "墓地摆件" },
        { name: "墓地摆件" },
        { name: "墓地摆件" },
        { name: "墓地摆件" },
      ],
      rankOne: null,
      // randList: [
      //     { id:1,ind:100,src: require("@/assets/images/rankTwo.png") },
      //     { id:2,ind:99,src: require("@/assets/images/rankThree.png") },
      //     { id:3,ind:98,src: require("@/assets/images/rankFour.png") },
      // ],
      listOne: [],
      goodList: [],
      listData: [],
    };
  },
  mounted() {
    this.goodsCategory();
    this.search();
    this.homePageGoodList();
  },
  methods: {
    listCLick(index) {
      this.rankOne = index;
      this.search();
      this.homePageGoodList();
    },
    click_left() {
      this.clickCount++;
      this.clickCount > 7 && (this.clickCount = 7);
    },
    click_right() {
      this.clickCount--;
      this.clickCount < 0 && (this.clickCount = 0);
    },
    goodsCategory() {
      api.taoruShopGoodsCategory({}, (res) => {
        console.log(res);
        this.listData = res.data;
      });
    },
    search() {
      let searchform = this.searchForm;
      searchform.search = {
        id: this.rankOne || undefined,
      };
      api.homePageGoodList(searchform, (res) => {
        this.listOne = res.resultList;
        this.total = res.resultCount;
      });
    },
    //好物排行前3个
    homePageGoodList() {
      let searchForm = {
        page: 1,
        pageSize: 20,
        search: { id: this.rankOne || undefined },
      };
      api.homePageGoodList(searchForm, (res) => {
        this.goodList = res.resultList.slice(0, 3);
      });
    },
    // goodsDetail(item) {
    //   sessionStorage.setItem("itemDetails", JSON.stringify(item));
    //   this.$router.push({ path: "/GoodsDetail" });
    // },
  },
};
</script>
<style lang="less" scoped>
.rankingList {
  font-family: PingFangSC-Regular, PingFang SC;
  .aboutTop {
    padding: 12px 0;
  }
  .bread {
    width: 1120px;
    margin: auto;
    height: 17px;
    font-size: 12px;
    font-weight: 400;
    color: #929292;
    line-height: 17px;
    letter-spacing: 1px;
    .textColor {
      color: #299654;
    }
  }
  .rankingListTop {
    width: 100%;
    height: 73px;
    background: #299654;
    margin-top: 32px;
    position: relative;
  }
  .rankingListTopB {
    width: 1120px;
    height: 100%;
    margin: auto;
    position: relative;
    .click_left,
    .click_right {
      position: absolute;
      width: 34px;
      height: 34px;
      background: #ffffff;
      border-radius: 50%;
      font-size: 24px;
      top: 20px;
      color: #c0c0c0;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .click_left {
      left: -80px;
    }
    .click_right {
      right: -80px;
    }
  }
  .rankingListTopBM {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .rankingListTopBox {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    div {
      width: 120px;
      height: 33px;
      text-align: center;
      font-size: 24px;
      font-weight: 400;
      color: #ffffff;
      line-height: 73px;
      letter-spacing: 1px;
      position: relative;
      cursor: pointer;
      span {
        position: relative;
        z-index: 3;
      }
      .randOne {
        width: 120px;
        height: 84px;
        position: absolute;
        top: -10px;
        left: -1px;
        z-index: 0;
      }
    }
    div:not(:nth-child(1)) {
      margin-left: 60px;
    }
  }
  .rankingListMain {
    width: 1120px;
    margin: 24px auto 40px;
    .rankingListMainBox {
      width: 100%;
      .rankingListMainBoxTop {
        display: flex;
        justify-content: space-between;
        .rankingListMainBoxTopBox {
          width: 365px;
          height: 249px;
          background: #ffffff;
          border-radius: 2px;
          position: relative;
          padding: 69px 20px 20px;
          .divOne {
            width: 50px;
            height: 29px;
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 29px;
            z-index: 2;
            position: absolute;
            top: 18px;
            left: -10px;
            text-align: center;
          }
          .divTwo {
            width: 107px;
            height: 29px;
            font-size: 18px;
            font-weight: 400;
            line-height: 29px;
            z-index: 2;
            position: absolute;
            top: 18px;
            left: 50px;
          }
          .randListImg {
            width: 177px;
            height: 38px;
            position: absolute;
            top: 18px;
            left: -10px;
            z-index: 1;
          }
          .divThree {
            display: flex;
            flex-wrap: nowrap;
            .divThreeImg {
              width: 120px;
              height: 160px;
              border-radius: 2px;
            }
            div {
              margin-left: 16px;
              p:nth-child(1) {
                height: 18px;
                font-size: 18px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #1a1a1a;
                line-height: 18px;
                letter-spacing: 1px;
                margin-top: 20px;
                display: -webkit-box;
                overflow: hidden;
                white-space: normal !important;
                text-overflow: ellipsis;
                word-wrap: break-word;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
              }
              p:nth-child(2) {
                width: 193px;
                height: 36px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #838383;
                line-height: 18px;
                margin-top: 16px;
                display: -webkit-box;
                overflow: hidden;
                white-space: normal !important;
                text-overflow: ellipsis;
                word-wrap: break-word;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              }
              p:nth-child(3) {
                margin-top: 24px;
                span {
                  height: 33px;
                  font-size: 24px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #e64747;
                  line-height: 33px;
                  letter-spacing: 1px;
                }
                span:nth-child(2) {
                  height: 17px;
                  font-size: 12px;
                  font-weight: 400;
                  color: #838383;
                  line-height: 17px;
                  text-decoration: line-through;
                  margin-left: 4px;
                }
              }
            }
          }
        }
      }
      .rankingListMainBoxBody {
        display: flex;
        flex-wrap: wrap;
        .divFive:not(:nth-child(5n + 1)) {
          margin-left: 12px;
        }
        .divFive:hover {
          cursor: pointer;
          transition: all 0.2s;
          transform: scale(1.1);
        }
        .divFive {
          // padding: 16px;
          width: 214px;
          height: 320px;
          background: #ffffff;
          border: 1px solid #f8f8f8;
          cursor: pointer;
          margin-top: 20px;
          position: relative;
          .divFiveImg {
            position: absolute;
            width: 56px;
            height: 36px;
            top: 16px;
            left: -10px;
          }
          .divfix {
            position: absolute;
            width: 54px;
            height: 29px;
            line-height: 29px;
            text-align: center;
            top: 16px;
            left: -10px;
          }
          .divBox {
            width: 214px;
            height: 214px;
            background: #d8d8d8;
            border-radius: 2px 2px 0px 0px;
            padding: 16px;
            img {
              width: 176px;
              height: 175px;
              margin: auto;
              margin-bottom: 8px;
            }
          }
          .hotspotBoxSmp {
            margin-top: 8px;
            margin-left: 16px;
            margin-right: 16px;
            width: 176px;
            height: 48px;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #838383;
            line-height: 24px;
            letter-spacing: 1px;
            display: -webkit-box;
            overflow: hidden;
            white-space: normal !important;
            text-overflow: ellipsis;
            word-wrap: break-word;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .hotspotBoxPri {
            margin-left: 16px;
            margin-right: 16px;
            display: flex;
            justify-content: space-between;
            margin-top: 5px;
            div:nth-child(1) {
              span:nth-child(1) {
                height: 33px;
                font-size: 24px;
                font-weight: 400;
                color: #e64747;
                line-height: 33px;
              }
              span:nth-child(2) {
                height: 17px;
                font-size: 12px;
                font-weight: 400;
                color: #838383;
                line-height: 17px;
                text-decoration: line-through;
                margin-left: 4px;
              }
            }
            div:nth-child(2) {
              height: 17px;
              font-size: 12px;
              font-weight: 400;
              color: #838383;
              line-height: 42px;
            }
          }
        }
      }
    }
  }
}
</style>
